import React from "react";
import * as Comp from 'components/index';
import * as Ui from "components/ui";
import * as Sections from "components/sections";

const content = function (props = null) {
  let subject = (props && props.subject) ? props.subject : ''
  let keyRef = (props && props.keyRef) ? props.keyRef : 'default'
  return {
      preTitle: "Storage-to-storage backup",
    title: (<>Automate your <span className="text-primary">{subject}</span> Backups</>),
      description: (
        <>
          <p>
            <span className="link-highlight">Store {subject} backups on any storage solution.</span><br/>
            Enjoy all the integrations, tools, monitoring systems, to make it a breeze.
            Be notified, when something goes wrong, give access to your team or customers, and more.
            <div className="my-6">
              <p className="mb-2 font-light text-xs tracking-wider uppercase opacity-75">Connect any storage provider</p>
              <Sections.StorageProvidersList subject={subject} />
            </div>
            <Ui.FeatureChecks items={['Flexible Scheduling', 'Advanced notifications', 'Incremental backup', 'Fail-over detection']} />
            <Ui.Link arrow="right" to="/catalog">Discover all integrations</Ui.Link>
          </p>
        </>
      ),
      image: ( <Comp.Image
        className=""
        filename={`simplebackups-${keyRef}-backup-automation-providers.png`}
        alt={`Backup ${subject} to any storage provider`}
        style={{maxWidth: 550}}
      />)
    }
};

export default content;